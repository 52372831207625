import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Flex, Box } from 'theme-ui';

import Layout from '../global/Layout';
import Text from '../components/Text';
import Button from '../components/Button';
import Stack from '../components/Stack';
import Seo from '../components/Seo';

export const query = graphql`
  query getFilters {
    strapi {
      fishingMethods {
        name
        id
      }
      fishSpecies {
        name
        id
      }
    }
  }
`;

const IndexPage = ({ data: { strapi } }) => {
  const [form, setState] = useState({});
  function isActive(namespace, value) {
    if (!form[namespace]) {
      return false;
    }
    return form[namespace].has(value);
  }

  function setFilter(filterName, value) {
    setState((prevState) => {
      const values = prevState[filterName] || new Set();
      if (values.has(value)) {
        values.delete(value);
      } else {
        values.add(value);
      }
      return {
        ...prevState,
        [filterName]: values,
      };
    });
  }

  function makeQuery() {
    const target = '/find?';
    const params = Object.keys(form)
      .reduce((acc, item) => {
        acc.push(`${item}=${[...form[item]].join(',')}`);
        return acc;
      }, [])
      .join('&');
    return target + params;
  }

  return (
    <Layout fullWidth transparentHeader stickyHeader={false} showFooter={false}>
      <Seo
        title= "Fiskeguider och boende"
        description="Fiskeguider och boende för din nästa fiskeresa. Från Gotland till Arjeplog. Reel North har sveriges bästa fiskeresor"
        image = "https://s3.eu-west-1.amazonaws.com/cdn.wefishtheworld.com/5306ae3da8434c02b74ab4dc669c95ca.jpg"
      />
      <Flex
        sx={{
          flexDirection: ['column-reverse', 'row'],
          justifyContent: 'center',
          height: 'auto',
          flex: '1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '0 1 100%',
            height: '90vh',
            textAlign: 'left',
            justifyContent: 'center',
          }}
        >
          <Flex
            sx={{
              height: 'auto',
              flex: '1 1 100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Stack align="center" justify="center" space="4">
              <Box sx={{ width: '80%', mx: 'auto', px: 3 }}>
                <Text.Hero
                  sx={{
                    color: 'white',
                    textShadow: '1px 1px 1px black',
                  }}
                >
                  Boka din nästa fiskeresa
                </Text.Hero>
              </Box>
              <Flex sx={{ justifyContent: 'center' }}>
                <Link to={makeQuery()}>
                  <Button as="span" size="large" variant="success">
                  <b>Visa platser</b>
                  </Button>
                </Link>
              </Flex>
            </Stack>

            <Flex
              sx={{
                justifyContent: 'center',
                flexDirection: ['column', 'column'],
                alignItems: 'center',
                opacity: 0.7,
                bg: '#F2F2F2',
                py: 2,
                my: 4,
                borderRadius: 5,
              }}
            >
              <Flex
                sx={{
                  flexDirection: ['column', 'row'],
                  justifyContent: 'space-between',
                  width: ['100%'],
                  maxWidth: '740px',
                  px: 3,
                  mt: 3,
                }}
              >
                <Box sx={{ width: ['100%', '50%'] }}>
                  <Stack>
                    <Text.H4>Välj fiskemetod</Text.H4>
                    <Flex sx={{ flexWrap: 'wrap' }}>
                      {strapi.fishingMethods.map(({ id, name }) => (
                        <Box sx={{ px: 1, py: 1 }} key={id}>
                          <Button
                            onClick={() => setFilter('fishingMethods', id)}
                            variant={
                              isActive('fishingMethods', id)
                                ? 'success'
                                : 'outline'
                            }
                            size="small"
                          >
                            <Text.Span>{name}</Text.Span>
                          </Button>
                        </Box>
                      ))}
                    </Flex>
                  </Stack>
                </Box>

                <Box sx={{ my: [3, 0], width: ['100%', '50%'] }}>
                  <Stack>
                    <Text.H4>Välj fiskart</Text.H4>
                    <Flex sx={{ flexWrap: 'wrap' }}>
                      {strapi.fishSpecies.map(({ id, name }) => (
                        <Box sx={{ p: 1 }} key={id}>
                          <Button
                            onClick={() => setFilter('fishSpecies', id)}
                            variant={
                              isActive('fishSpecies', id)
                                ? 'success'
                                : 'outline'
                            }
                            size="small"
                          >
                            <Text.Span>{name}</Text.Span>
                          </Button>
                        </Box>
                      ))}
                    </Flex>
                  </Stack>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            flex: ['1 1 100%', '1 1 100%'],
            height: ['100vh'],
            backgroundSize: 'cover',
            backgroundPosition: ['center', 'top'],
            backgroundImage: `
            url('https://s3.eu-west-1.amazonaws.com/cdn.wefishtheworld.com/background.png')`,
          }}
        />
      </Flex>
    </Layout>
  );
};

export default IndexPage;
