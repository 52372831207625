import React from 'react';
import { Flex, Button, Box } from 'theme-ui';

const textOverflowStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
const sizes = {
  xsmall: {
    px: 1,
    py: 0,
  },
  small: {
    px: 2,
    py: 0,
  },
  medium: {
    px: 3,
    py: 2,
  },
  large: {
    px: 5,
    py: 3,
  },
};

export default ({
  children,
  rightIcon,
  leftIcon,
  textOverflow,
  size = 'medium',
  sx = {},
  ...rest
}) => (
  <Button
    sx={{
      ...sx,
      transition: 'all 0.2s ease-out',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'body',
      ...sizes[size],
    }}
    {...rest}
  >
    <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
      {leftIcon ? (
        <Box as="span" mr={2}>
          {leftIcon}
        </Box>
      ) : null}
      <Box
        as="span"
        sx={{ ...(textOverflow ? textOverflowStyles : {}), whiteSpace: 'pre' }}
      >
        {children}
      </Box>
      {rightIcon ? (
        <Box as="span" ml={2}>
          {rightIcon}
        </Box>
      ) : null}
    </Flex>
  </Button>
);
